const TaxItem = props => {

	return (
		<div className='d-flex justify-content-between align-items-center mb-4'>
			<p className='lead mb-0'>Tax</p>
			<p className='lead mb-0'>{`$${props.taxAmount}`}</p>
		</div>
	);

}

export default TaxItem;