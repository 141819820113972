import { createContext, useState } from 'react';
import { getFBUserInfo, getFBUserInfoByEmail } from '../helpers/firebase-helpers';

const UserContext = createContext({
	userId: '',
	guestId:'',
	email:'',
	firstName: '',
	lastName: '',
	stripeCustomerId: '',
	isAdmin: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	resetUserData: ()=>{},
	getUserInfo: userId => {},
	getUserInfoByEmail: email => {},
});

export const UserContextProvider = props => {

	const [userId, setUserId] = useState('');
	const [guestId, setGuestId] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [stripeCustomerId, setStripeCustomerId] = useState('');
	const [lastName, setLastName] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const resetUserData = () => {
		resetStates();
		setUserId('');
		setGuestId('');
		setEmail('');
		setFirstName('');
		setLastName('');
		setStripeCustomerId('');
		setIsAdmin('');
	}

	const handleSuccess = (userId, guestId, email, firstName, lastName, stripeCustomerId, isAdmin) => {

		setUserId(userId);
		setGuestId(guestId);
		setEmail(email);
		setFirstName(firstName);
		setLastName(lastName);
		setStripeCustomerId(stripeCustomerId);
		setIsAdmin(isAdmin);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const getUserInfo = async userId => {
		resetStates();
		setIsLoading(true);
		const user = await getFBUserInfo(userId);

		if (user){
			handleSuccess(
				userId,
				user.guestId,
				user.email,
				user.firstName,
				user.lastName,
				user.stripeCustomerId,
				user.isAdmin
			);
			return user;
		}else {
			handleFail({message: 'Failed to get user data'});
			return; 
		}
	}

	const getUserInfoByEmail = async email => {
		resetStates();
		setIsLoading(true);
		const user = await getFBUserInfoByEmail(email);
		if (user){
			handleSuccess(
				user.userId,
				user.guestId,
				email,
				user.firstName,
				user.lastName,
				user.stripeCustomerId,
				user.isAdmin
			);
			return user;
		}else {
			handleFail({message: 'Failed to get user data'});
			return; 
		}
	}

	return (
		<UserContext.Provider value={{
			userId: userId,
			guestId: guestId,
			email: email,
			firstName: firstName,
			lastName: lastName,
			stripeCustomerId: stripeCustomerId,
			error: error,
			isLoading: isLoading,
			isSuccessful: isSuccessful,
			resetUserData: resetUserData,
			getUserInfo: getUserInfo,
			getUserInfoByEmail: getUserInfoByEmail,
		}}>
			{props.children}
		</UserContext.Provider>
	)
}

export default UserContext;