import { createContext, useState, useContext } from 'react';
import { createFBInfluencer, getFBInfluencers, getFBInfluencer, getAllOrdersWithCouponCode, updateFBInfluencer, deleteFBInfluencer } from '../helpers/firebase-helpers';

import AlertContext, {alertMessages} from './alert-context'

const InfluencersContext = createContext({
	influencers: [],
	selectedInfluencer: {},
	isLoading: '',
	error: '',
	isSuccessful: '',
	selectInfluencer: influencerId => {},
	createInfluencer: influencerData => {},
	getInfluencers: ()=>{},
	getInfluencer: influencerId => {}, 
	getInfluencerOrders: couponCode => {},
	updateInfluencer: (influencerId, dataToUpdate) => {},
	deleteInfluencer: influencerId => {} 
});

export const InfluencersContextProvider = props => {

	const alertCtx = useContext(AlertContext);

	const [influencers, setInfluencers] = useState('');
	const [selectedInfluencer, setSelectedInfluencer] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);


	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}


	const handleSuccess = influencers => {
		setInfluencers(influencers);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleInfluencerSuccess = influencer => {
		setSelectedInfluencer(influencer);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const selectInfluencer = influencerId =>{
		const newSelectedInfluencer = influencers.find(influencer=>influencer.influencerId === influencerId);
		if (newSelectedInfluencer) setSelectedInfluencer(newSelectedInfluencer);
		return;
	}

	const createInfluencer = async influencerData => {
		resetStates();
		setIsLoading(true);
		const influencer = await createFBInfluencer(influencerData)
		if (influencer) {
			const updatedInfluencers = [influencer, ...influencers];
			handleSuccess(updatedInfluencers);
			alertCtx.setAlert('success', alertMessages.INFLUENCER_CREATED);
			return updatedInfluencers;
		} else {
			handleFail({message: 'Influencer creation failed'});
			return;
		}
	}

	const getInfluencers = async () => {
		resetStates();
		setIsLoading(true);

		const influencers = await getFBInfluencers();
		if (influencers) {
			handleSuccess(influencers);
			return influencers;
		} else {
			handleFail({message: 'There aren\'t any influencers'});
			return;
		}
	}

	const getInfluencer = async influencerId => {
		resetStates();
		setIsLoading(true);

		const influencer = await getFBInfluencer(influencerId);
		if (influencer) {
			handleInfluencerSuccess(influencer);
			return influencer;
		} else {
			handleFail({message: 'There aren\'t any matching influencers'});
			return;
		}
	}

	const getInfluencerOrders = async couponCode => {
		resetStates();
		setIsLoading(true);

		const orders = await getAllOrdersWithCouponCode(couponCode);
		if (orders) {
			const updatedInfluencer = {...selectedInfluencer};
			updatedInfluencer.orders = [...orders];

			const updatedInfluencers = [...influencers];
			const index = updatedInfluencers.findIndex(influencer => influencer.influencerId === updatedInfluencer.influencerId);
			if (index > -1) {
				updatedInfluencers[index] = {...updatedInfluencer};
				setInfluencers(updatedInfluencers);
			}
			handleInfluencerSuccess(updatedInfluencer);
			return orders;
		}
		return false; 
	}

	const updateInfluencer = async (influencerId, dataToUpdate) => {
		resetStates();
		setIsLoading(true);

		const response = await updateFBInfluencer(influencerId, dataToUpdate);
		if (response.type === 'success'){
			const influencer = {...response.data}
			const updatedInfluencers = [...influencers];
			const index = updatedInfluencers.findIndex(influencer => influencer.influencerId === influencerId);
			if (index > -1) {
				updatedInfluencers[index] = {...influencer};
				setInfluencers(updatedInfluencers);
			}
			handleInfluencerSuccess(influencer);
			alertCtx.setAlert('success', alertMessages.INFLUENCER_UPDATED);
			return influencer;
		}
		if (response.type === 'error') {
			handleFail({message: response.message});
			return false
		}
	}

	const deleteInfluencer = async influencerId => {
		resetStates();
		setIsLoading(true);

		try {
			await deleteFBInfluencer(influencerId)
			const updatedInfluencers = [...influencers];
			const index = updatedInfluencers.findIndex(influencer => influencer.influencerId === influencerId);
			if (index > -1) updatedInfluencers.splice(index, 1);
			handleSuccess(updatedInfluencers);
			alertCtx.setAlert('success', alertMessages.INFLUENCER_DELETED);
		} catch (error) {
			handleFail({message: error.message});
			return;
		}
	}

	return (
		<InfluencersContext.Provider value={{
			influencers: influencers,
			selectedInfluencer: selectedInfluencer,
			isLoading: isLoading,
			error: error,
			isSuccessful: isSuccessful,
			selectInfluencer: selectInfluencer,
			createInfluencer: createInfluencer,
			getInfluencers: getInfluencers,
			getInfluencer: getInfluencer,
			getInfluencerOrders: getInfluencerOrders,
			updateInfluencer: updateInfluencer,
			deleteInfluencer: deleteInfluencer
		}}>
			{props.children}
		</InfluencersContext.Provider>
	)

}

export default InfluencersContext;