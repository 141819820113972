import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {AuthContextProvider} from './store/auth-context';
import {UserContextProvider} from './store/user-context';
import {OrdersContextProvider} from './store/orders-context';
import {RecipesContextProvider} from './store/recipes-context';
import {InfluencersContextProvider} from './store/influencers-context';
import {AlertContextProvider} from './store/alert-context';

// Components
import App from './containers/App/App';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Other
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AlertContextProvider> 
      <AuthContextProvider> 
        <UserContextProvider> 
          <OrdersContextProvider> 
            <RecipesContextProvider> 
              <InfluencersContextProvider> 
                <App /> 
              </InfluencersContextProvider>  
            </RecipesContextProvider>
          </OrdersContextProvider> 
        </UserContextProvider> 
      </AuthContextProvider>
    </AlertContextProvider> 
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
