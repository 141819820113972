import { useContext } from 'react';
import { Link } from "react-router-dom";
import RecipesContext from '../../../store/recipes-context';

import {transformSecondsToDisplayDate} from '../../../helpers/misc-helpers';

import styles from './Recipe.module.css';

const Recipe = props => {

  const recipesCtx = useContext(RecipesContext);

  let recipeDate ='Just created';
  if (props.recipe.createdAt) recipeDate = transformSecondsToDisplayDate(props.recipe.createdAt.seconds);  

	const selectRecipe = () => recipesCtx.selectRecipe(props.recipe.recipeId);
  const deleteRecipe = () => recipesCtx.deleteRecipe(props.recipe.recipeId); 

  return (
		<div className={styles.Recipe}>
      <div>
        <h4 className='mb-0'>{props.recipe.title}</h4>
        <p className='mb-0 text-gray'><small>Created on: {recipeDate}</small></p>
        <p className='lead mb-0'>{props.recipe.description}</p>
      </div>
      <div className={styles.Buttons}>
        <Link to={`/recipes/update/${props.recipe.recipeId}`}>
          <button className='btn btn-link' onClick={selectRecipe}>
            Edit
          </button>
        </Link>
        <button className='btn btn-link text-danger' onClick={deleteRecipe}>Delete</button>
      </div>
    </div>
	)
}

export default Recipe;