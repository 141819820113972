export const transformSecondsToDate = seconds => {
	var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(seconds);
  return t;
} 

export const transformSecondsToDisplayDate = seconds => {
	const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	
	const date = transformSecondsToDate(seconds);
	const dateAsString = date.toString();
	const dateAsArray = dateAsString.split(' ');

	const day = dateAsArray[2];
	const month = months[date.getMonth()];
	const year = dateAsArray[3];

	return `${month} ${day}, ${year}`;
}

export const slugify = copy => {
	const slug = copy.replace(/ /g, '-').toLowerCase();
	return slug;
}

export const turnArrayToStringList = arrayOfStrings => {
	let stringVersion = ''
	for(let i = 0; i < arrayOfStrings.length; i++) {
		stringVersion = stringVersion + arrayOfStrings[i];
		if (i !== arrayOfStrings.length-1) stringVersion = stringVersion + '\n'
	}
	return stringVersion;
} 