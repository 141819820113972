import styles from './BtnText.module.css';

const BtnText = props => {

	const passOnClasses = props.passOnClasses ? props.passOnClasses : '';

	return(
		<div 
			className={`btn btn-link d-flex align-items-center ${styles.BtnText} ${passOnClasses}`}
			onClick={props.clickHandler}
		>
			{props.children}
		</div>
	)
}

export default BtnText