import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RecipesContext from '../../store/recipes-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Loader from '../../components/UIElements/Loader/Loader';
import UpdateRecipeForm from '../../components/Forms/UpdateRecipeForm/UpdateRecipeForm';

const UpdateRecipe = () => {

	const recipesCtx = useContext(RecipesContext);
	const { recipeId } = useParams();

	useEffect(()=>{
		if(recipesCtx.recipes.lenghth === 0) recipesCtx.getRecipes();
		if (!recipesCtx.selectedRecipe) recipesCtx.getRecipe(recipeId);
	}, [recipesCtx.selectedRecipe]);

	let formArea = null;
	if (recipesCtx.isLoading) formArea = <Loader>Loading recipe...</Loader>
	if (recipesCtx.selectedRecipe && recipesCtx.isSuccessful) {
		formArea = <UpdateRecipeForm />
	}	

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 my-5'>
						<h3>Update recipe </h3>
						{formArea}
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default UpdateRecipe;