import {Fragment, useContext} from 'react';
import useInput from '../../../hooks/use-input';

import InfluencersContext from '../../../store/influencers-context';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';

import {validateShortText, validateLongText, validateURL} from '../../../helpers/validation-functions';
import {slugify} from '../../../helpers/misc-helpers';

const UpdateInfluencerForm = props => {

	const influencersCtx = useContext(InfluencersContext);

	const firstName = useInput(validateShortText, influencersCtx.selectedInfluencer.firstName);
	const lastName = useInput(validateShortText, influencersCtx.selectedInfluencer.lastName);
	const title = useInput(validateShortText, influencersCtx.selectedInfluencer.title);
	const imageFileName = useInput(validateShortText, influencersCtx.selectedInfluencer.imageFileName);
	const couponCode = useInput(validateShortText, influencersCtx.selectedInfluencer.couponCode);
	const heroQuote = useInput(validateLongText, influencersCtx.selectedInfluencer.heroQuote);
	const storyQuote = useInput(validateLongText, influencersCtx.selectedInfluencer.storyQuote);

	const fields = [
		{
			fid: 0,
			elementType: 'input',
			label: 'First name',
      id: 'firstName',
      type: 'text',
      errorMessage: 'Please enter a valid name',
      value: firstName.value,
      disabled: false,
      isValid: firstName.isValid,
      hasError: firstName.hasError,
      valueChangedHandler: firstName.valueChangedHandler,
      touchHandler: firstName.touchHandler,
      reset: ()=>firstName.reset()
		},
		{
			fid: 1,
			elementType: 'input',
			label: 'Last name',
      id: 'lastName',
      type: 'text',
      errorMessage: 'Please enter a valid last name',
      value: lastName.value,
      disabled: false,
      isValid: lastName.isValid,
      hasError: lastName.hasError,
      valueChangedHandler: lastName.valueChangedHandler,
      touchHandler: lastName.touchHandler,
      reset: ()=>lastName.reset()
		},
		{
			fid: 2,
			elementType: 'input',
			label: 'Title',
      id: 'title',
      type: 'text',
      helpText: 'Eg. "Director of sales and amateur chef"',
      errorMessage: 'Please enter a valid title',
      value: title.value,
      disabled: false,
      isValid: title.isValid,
      hasError: title.hasError,
      valueChangedHandler: title.valueChangedHandler,
      touchHandler: title.touchHandler,
      reset: ()=>title.reset()
		},
		{
			fid: 3,
			elementType: 'input',
			label: 'Image file name',
      id: 'imageFileName',
      type: 'text',
      helpText: 'Eg. "aron-harrity.webp"',
      errorMessage: 'Please enter a valid file name',
      value: imageFileName.value,
      disabled: false,
      isValid: imageFileName.isValid,
      hasError: imageFileName.hasError,
      valueChangedHandler: imageFileName.valueChangedHandler,
      touchHandler: imageFileName.touchHandler,
      reset: ()=>imageFileName.reset()
		},
		{
			fid: 4,
			elementType: 'input',
			label: 'Coupon code',
      id: 'couponCode',
      type: 'text',
      helpText: 'Eg. "ARON15"',
      errorMessage: 'Please enter a valid title',
      value: couponCode.value,
      disabled: false,
      isValid: couponCode.isValid,
      hasError: couponCode.hasError,
      valueChangedHandler: couponCode.valueChangedHandler,
      touchHandler: couponCode.touchHandler,
      reset: ()=>couponCode.reset()
		},
		{
			fid: 5,
			elementType: 'textarea',
			label: 'Hero quote / testimonial',
      id: 'heroQuote',
      rows: 4,
      type: 'text',
      helpText: 'This goes at the top of the page. Don\'t put it in quotes.',
      errorMessage: 'Please enter a valid quote',
      value: heroQuote.value,
      disabled: false,
      optional: false,
      isValid: heroQuote.isValid,
      hasError: heroQuote.hasError,
      valueChangedHandler: heroQuote.valueChangedHandler,
      touchHandler: heroQuote.touchHandler,
      reset: ()=>heroQuote.reset()
		},
		{
			fid: 6,
			elementType: 'textarea',
			label: 'Story quote / testimonial',
      id: 'storyQuote',
      rows: 4,
      type: 'text',
      helpText: 'This goes below the fold in the "Person X\'s story" section. Don\'t put it in quotes.',
      errorMessage: 'Please enter a valid quote',
      value: storyQuote.value,
      disabled: false,
      optional: false,
      isValid: storyQuote.isValid,
      hasError: storyQuote.hasError,
      valueChangedHandler: storyQuote.valueChangedHandler,
      touchHandler: storyQuote.touchHandler,
      reset: ()=>storyQuote.reset()
		}
	];

	const submitHandler = async event => {
		event.preventDefault();

		const slug = slugify(`${firstName.value}-${lastName.value}`);

		const influencerData = {
			slug: slug,
			firstName: firstName.value,
			lastName: lastName.value,
			title: title.value,
			imageFileName: imageFileName.value,
			couponCode: couponCode.value.toUpperCase(),
			heroQuote: heroQuote.value,
			storyQuote: storyQuote.value
		}
		
		const updatedInfleuncer = await influencersCtx.updateInfluencer(influencersCtx.selectedInfluencer.influencerId, influencerData);		

	}

	return (
		<Form fields={fields} submitHandler={submitHandler} serverError={''}>
			Update influencer
		</Form>
	)
}

export default UpdateInfluencerForm;

