import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import CreateRecipeForm from '../../components/Forms/CreateRecipeForm/CreateRecipeForm';

const CreateRecipe = () => {

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 my-5'>
						<h3>Add new recipe</h3>
						<CreateRecipeForm />
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default CreateRecipe;