import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import CreateInfluencerForm from '../../components/Forms/CreateInfluencerForm/CreateInfluencerForm';

const CreateInfluencer = () => {

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 my-5'>
						<h3>Add a new influencer</h3>
						<CreateInfluencerForm />
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default CreateInfluencer;