import {Fragment, useState, useEffect} from 'react';
import Input from './Input/Input';
import SectionHeader from './SectionHeader/SectionHeader';
import AddMoreFieldsBtn from './AddMoreFieldsBtn/AddMoreFieldsBtn';
import PrimaryCTA from '../Buttons/PrimaryCTA/PrimaryCTA';
import Error from '../Error/Error';

const Form = props => {

	const [formIsValid, setFormIsValid] = useState(false);
	
	const fields = props.fields.map(field=>{
		if (field.elementType === 'sectionHeader') {
			return <SectionHeader key={field.fid}>{field.title}</SectionHeader>
		}
		if (field.elementType === 'addMore') {
			return <AddMoreFieldsBtn clickHandler={field.clickHandler} key={field.fid}>{field.cta}</AddMoreFieldsBtn>
		}
		if (field.hidden) return; 
		if (props.inline) {
			return (
				<div className='col-12 flex-grow-1' key={field.fid}>
					<Input key={field.fid} field={field} inline noMargin={props.noMargin}/>
				</div>
			);
		}
		return <Input key={field.fid} field={field} />
	});

	useEffect(()=>{
		let hasInvalidField = false;
		for (let i=0; i<props.fields.length; i++){
			if(props.fields[i].hasOwnProperty('value') && !props.fields[i].isValid) {
				if (!props.fields[i].optional) hasInvalidField = true;
				if (props.fields[i].optional && props.fields[i].value !== '') hasInvalidField = true;
			}
		}
		setFormIsValid(!hasInvalidField);
	}, props.fields);

	const error = props.serverError ? <Error errorCode={props.serverError.code}>{props.serverError.message}</Error> : null;
	
	const inlineFormClasses = props.inline ? 'row row-cols-lg-auto align-items-end' : ''; 
	const smallSized = props.inline ? true : false; 

	let inlineButtonClasses = '' 
	if (props.inline)  inlineButtonClasses = 'mb-3';
	if (props.inline && props.noMargin)  inlineButtonClasses = 'mb-0';

	return (
		<Fragment>
			{error}
			<form onSubmit={props.submitHandler} className={inlineFormClasses}>
				{fields}
				<div className='col-12'>
	        <PrimaryCTA 
	        	disabled={!formIsValid} 
	        	mediumSized={!smallSized} 
	        	smallSized={smallSized} 
	        	passOnClasses={inlineButtonClasses}
	        >
	        	{props.children}
	        </PrimaryCTA>
	      </div>
			</form>
		</Fragment>
	)
} 

export default Form;