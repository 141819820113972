import { useState, useContext } from 'react';

import OrdersContext from '../../../store/orders-context';

import PromoCodeInfo from './PromoCodeInfo/PromoCodeInfo';
import OrderItem from './OrderItem/OrderItem';
import ShippingItem from './ShippingItem/ShippingItem';
import TaxItem from './TaxItem/TaxItem';
import ShippingAddress from './ShippingAddress/ShippingAddress';
import DollarDiscountItem from './DollarDiscountItem/DollarDiscountItem';
import Select from '../../../components/UIElements/Form/Select/Select';
import Loader from '../../../components/UIElements/Loader/Loader';

import {transformSecondsToDisplayDate} from '../../../helpers/misc-helpers';

import styles from './Order.module.css';

const Order = props => {

	const ordersCtx = useContext(OrdersContext);

	const orderDate = transformSecondsToDisplayDate(props.order.createdAt.seconds);	
	const orderItems = props.order.itemsPurchased.map(item=><OrderItem item={item} key={item.itemName} />)

	const [orderStatus, setOrderStatus] = useState(props.order.status); 

	const selectField = {
		value: props.order.status,
		valueChangedHandler: event => ordersCtx.updateOrderStatus(props.order.orderId, event.target.value),
		options: [
			{
				value: 'new',
				displayValue: 'New order'
			},
			{
				value: 'inPrep',
				displayValue: 'Being prepared'
			},
			{
				value: 'shipped',
				displayValue: 'Shipped'
			},
			{
				value: 'cancelled',
				displayValue: 'Cancelled'
			},
			{
				value: 'outOfStock',
				displayValue: 'Out Of Stock'
			},
			{
				value: 'disputed',
				displayValue: 'Disputed'
			},
			{
				value: 'refunded',
				displayValue: 'Refunded'
			}
		],
		hasError: props.order.error,
		errorMessage: props.order.error ? 'There was an error updating your order' : ''
	}

	const orderStatusDisplayValue = selectField.options.find(option=>option.value===props.order.status).displayValue;

	let orderStatusDisplay = <h3 className='mb-0'>{orderStatusDisplayValue}</h3>;
	if (props.updatable) orderStatusDisplay = props.order.updatingStatus ? <Loader>Loading...</Loader> : <Select field = {selectField} large />
	
	let dollarDiscountElement = null;
	if (props.order.promotionCode) {
		if (props.order.promotionCode.amountOff>0) dollarDiscountElement = <DollarDiscountItem discountAmount={props.order.promotionCode.amountOff} />
	} 
	
	return (
		<div className={styles.Order}>
			<div className={styles.Header}>
				<div>
					{orderStatusDisplay}
					<p className='mb-0'><small>{`Order date: ${orderDate}`}</small></p>
				</div>
				<h3 className='mb-0'>{`$${props.order.totalPrice}`}</h3>
			</div>
			<div className='py-3'>
				{props.order.promotionCode && <PromoCodeInfo promotionCode={props.order.promotionCode}/>}
				{orderItems}
				<ShippingItem price={props.order.shippingPrice} />
				{(props.order.tax>0) && <TaxItem taxAmount={props.order.tax} />}
				{dollarDiscountElement}
			</div>
			<ShippingAddress order={props.order} />
		</div>
	)

}

export default Order;