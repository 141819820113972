import { createContext, useState, useContext } from 'react';
import { createFBRecipe, getFBRecipes, getFBRecipe, updateFBRecipe, deleteFBRecipe } from '../helpers/firebase-helpers';

import AlertContext, {alertMessages} from './alert-context'

const RecipesContext = createContext({
	recipes: [],
	selectedRecipe: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	selectRecipe: recipeId => {},
	createRecipe: recipeData => {},
	getRecipes: ()=>{},
	getRecipe: recipeId => {}, 
	updateRecipe: (recipeId, dataToUpdate) => {},
	deleteRecipe: recipeId => {} 
});

export const RecipesContextProvider = props => {

	const alertCtx = useContext(AlertContext);

	const [recipes, setRecipes] = useState('');
	const [selectedRecipe, setSelectedRecipe] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}


	const handleSuccess = recipes => {
		setRecipes(recipes);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleRecipeSuccess = recipe => {
		setSelectedRecipe(recipe);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const selectRecipe = recipeId =>{
		const newSelectedRecipe = recipes.find(recipe=>recipe.recipeId === recipeId);
		if (newSelectedRecipe) setSelectedRecipe(newSelectedRecipe);
		return;
	}


	const createRecipe = async recipeData => {
		resetStates();
		setIsLoading(true);
		const recipe = await createFBRecipe(recipeData)
		if (recipe) {
			const updatedRecipes = [recipe, ...recipes];
			handleSuccess(updatedRecipes);
			alertCtx.setAlert('success', alertMessages.RECIPE_CREATED);
			return updatedRecipes;
		} else {
			handleFail({message: 'Recipe creation failed'});
			return;
		}
	}

	const getRecipes = async () => {
		resetStates();
		setIsLoading(true);

		const recipes = await getFBRecipes();
		if (recipes) {
			handleSuccess(recipes);
			return recipes;
		} else {
			handleFail({message: 'There aren\'t any recipes'});
			return;
		}
	}

	const getRecipe = async recipeId => {
		resetStates();
		setIsLoading(true);

		const recipe = await getFBRecipe(recipeId);
		if (recipe) {
			handleRecipeSuccess(recipe);
			return recipe;
		} else {
			handleFail({message: 'There aren\'t any matching recipes'});
			return;
		}
	}

	const updateRecipe = async (recipeId, dataToUpdate) => {
		resetStates();
		setIsLoading(true);

		const response = await updateFBRecipe(recipeId, dataToUpdate);
		if (response.type === 'success'){
			const recipe = {...response.data}
			const updatedRecipes = [...recipes];
			const index = updatedRecipes.findIndex(recipe => recipe.recipeId === recipeId);
			if (index > -1) {
				updatedRecipes[index] = {...recipe};
				setRecipes(updatedRecipes);
			}
			handleRecipeSuccess(recipe);
			alertCtx.setAlert('success', alertMessages.RECIPE_UPDATED);
			return recipe;
		}
		if (response.type === 'error') {
			handleFail({message: response.message});
			return false
		}
	}

	const deleteRecipe = async recipeId => {
		resetStates();
		setIsLoading(true);

		try {
			await deleteFBRecipe(recipeId)
			const updatedRecipes = [...recipes];
			const index = updatedRecipes.findIndex(recipe => recipe.recipeId === recipeId);
			if (index > -1) updatedRecipes.splice(index, 1);
			handleSuccess(updatedRecipes);
			alertCtx.setAlert('success', alertMessages.RECIPE_DELETED);
		} catch (error) {
			handleFail({message: error.message});
			return;
		}
	}

	return (
		<RecipesContext.Provider value={{
			recipes: recipes,
			selectedRecipe: selectedRecipe,
			isLoading: isLoading,
			error: error,
			isSuccessful: isSuccessful,
			selectRecipe: selectRecipe,
			createRecipe: createRecipe,
			getRecipes: getRecipes,
			getRecipe: getRecipe,
			updateRecipe: updateRecipe,
			deleteRecipe: deleteRecipe
		}}>
			{props.children}
		</RecipesContext.Provider>
	)

}

export default RecipesContext;