import Footer from '../../Navs/Footer/Footer';
import MainNavbar from '../../Navs/MainNavbar/MainNavbar';

import Alert from '../../UIElements/Alert/Alert';

import styles from './MainLayout.module.css';

const MainLayout = props => {
	return (
		<div className={styles.MainLayout}>
			<div className={styles.ContentWrapper}>
				<MainNavbar />
				{props.children}
			</div>
			<Footer />
			<Alert />
		</div>
	)
}

export default MainLayout;