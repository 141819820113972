import styles from './PromoCodeInfo.module.css';

const PromoCodeInfo = props => {

	const discountAmount = (props.promotionCode.amountOff) > 0 ? `$${props.promotionCode.amountOff}` : `${props.promotionCode.percentOff}%`;

	return (
		<div className={styles.PromoCodeInfo}>
			<p className='mb-0'>
				{`Promocode "${props.promotionCode.codeName}" used for ${discountAmount} discount.`} 
			</p>
		</div>
	)
}

export default PromoCodeInfo;