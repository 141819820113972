import { createContext, useState, useContext } from 'react';
import UserContext from './user-context';

import { signInWithFBAuth } from '../helpers/firebase-helpers';
import { postData } from '../helpers/http-helpers';

const AuthContext = createContext({
	idToken: '',
	refreshToken: '',
	localId: '',
	isLoggedIn: '',
	isLoginFormSubmitted: '',
	isLoading: '',
	error: '',
	isSuccessful: '',
	initiateStates: ()=>{},
	setStates: ()=>{},
	setIsLoginFormSubmitted: boolean=>{},
	onLogin: userData => {},
	onLogout: ()=>{},
	onRefreshAuth: refreshToken => {},
})

export const AuthContextProvider = props => {

	const userCtx = useContext(UserContext);

	const [idToken, setIdToken] = useState(localStorage.getItem('TrueOliveAdminIdToken'));
	const [refreshToken, setRefreshToken] = useState(localStorage.getItem('TrueOliveAdminRefreshToken'));
	const [localId, setLocalId] = useState(localStorage.getItem('TrueOliveAdminLocalId'));
	const [isLoggedIn, setIsLoggedIn] = useState(idToken ? true : false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const fbKey = process.env.REACT_APP_FIREBASE_API_KEY;

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}

	const initiateStates = () => {
		setIsLoading(true);
		setError(null);
		setIsSuccessful(null);
	}

	const autoLogout = authDuration => {
		setTimeout(()=>{
			setIsLoggedIn(false);
			setIdToken('');
    	setLocalId('');
    	setRefreshToken('');

    	userCtx.resetUserData();

			localStorage.removeItem('TrueOliveAdminIdToken');
	  	localStorage.removeItem('TrueOliveAdminLocalId');
		}, authDuration) 
	}

	const handleAuthSuccess = (idToken, refreshToken, localId, authDuration) => {
		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
		setIsLoggedIn(true);

		setIdToken(idToken);
		setRefreshToken(refreshToken);
		setLocalId(localId);

		localStorage.setItem('TrueOliveAdminIdToken', idToken);
  	localStorage.setItem('TrueOliveAdminRefreshToken', refreshToken);
  	localStorage.setItem('TrueOliveAdminLocalId', localId);

  	autoLogout(authDuration);
	}

	const handleAuthFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const onLogin = async userData => {
		initiateStates();
		try{
			const authUser = await signInWithFBAuth(userData.email, userData.password);
			const tokens = authUser._tokenResponse;

			handleAuthSuccess(
				tokens.idToken, 
				tokens.refreshToken, 
				tokens.localId, 
				tokens.expiresIn*1000
			);

			return true;
		} catch (error) {
			handleAuthFail({message: error.message});
			return false;
		}
	}

	const onLogout = () => {
		resetStates();
		
		setIsLoggedIn(false);
		setIdToken('');
		setRefreshToken('');
		setLocalId('');

		userCtx.resetUserData();

		localStorage.removeItem('TrueOliveAdminIdToken');
  	localStorage.removeItem('TrueOliveAdminLocalId');
  	localStorage.removeItem('TrueOliveAdminRefreshToken');
	}

		const onRefreshAuth = async refreshToken => {
		resetStates();

		try{
			const response = await postData(
				`https://securetoken.googleapis.com/v1/token?key=${fbKey}`,
				{
					grant_type: 'refresh_token',
          refresh_token: refreshToken
				}
			);
			if (!response.ok) return handleAuthFail(response.error);
			return handleAuthSuccess(
				response.data.id_token, 
				response.data.refresh_token, 
				response.data.user_id, 
				response.data.expires_in*1000 
			);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<AuthContext.Provider value={{
			idToken: idToken,
			localId: localId,
			error: error,
			isLoading: isLoading,
			isSuccessful: isSuccessful,
			isLoggedIn: isLoggedIn,
			resetStates: resetStates,
			onLogin: onLogin,
			onLogout: onLogout,
			onRefreshAuth: onRefreshAuth
		}}>
			{props.children}
		</AuthContext.Provider>
	)
}

export default AuthContext;