import { createContext, useState } from 'react';

const AlertContext = createContext({
	isShown: '',
	isClosed: '',
	type: '',
	message: '',
	setAlert: (type, message) => {}
});

export const AlertContextProvider = props => {
	const [isShown, setIsShown] = useState(false);
	const [isClosed, setIsClosed] = useState(null);
	const [type, setType] = useState('');
	const [message, setMessage] = useState('');

	const setAlert = (type, message) => {
		setIsClosed(false);
		setIsShown(true);
		setType(type);
		setMessage(message);

		closeAlert();
	}

	const closeAlert = () => setTimeout(() => {
		setIsShown(false);
		setIsClosed(true);
		setType('');
		setMessage('');
		resetIsClosed();
	},3500)

	const resetIsClosed = () => setTimeout(()=>{setIsClosed(null)}, 500)

	return (
		<AlertContext.Provider value={{
			isShown: isShown,
			isClosed: isClosed,
			type: type,
			message: message,
			setAlert: setAlert
		}}>
			{props.children}
		</AlertContext.Provider>
	)
}

export const alertMessages = {
	RECIPE_CREATED: 'Your recipe was successfully created.',
	RECIPE_DELETED: 'Your recipe was successfully deleted.',
	RECIPE_UPDATED: 'Your recipe was successfully updated.',
	INFLUENCER_CREATED: 'The influencer was successfully created.',
	INFLUENCER_UPDATED: 'The influencer was successfully updated',
	INFLUENCER_DELETED: 'The influencer was successfully deleted.',
	ORDER_UPDATED: 'The order was successfully updated.',
	SIGN_IN_SUCCESS: 'Signed in successfully',
}

export default AlertContext;