const DollarDiscountItem = props => {

	return (
		<div className='d-flex justify-content-between align-items-center mb-4'>
			<p className='lead mb-0'>Discount applied</p>
			<p className='lead mb-0'>{`-$${props.discountAmount}`}</p>
		</div>
	);

}

export default DollarDiscountItem;