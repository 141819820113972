import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InfluencersContext from '../../store/influencers-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Order from '../../components/UIElements/Order/Order';

const InfluencerOrders = props => {

	const influencersCtx = useContext(InfluencersContext);
	const { influencerId } = useParams();

	useEffect(()=>{
		if(influencersCtx.influencers.lenghth === 0) influencersCtx.getInfluencers();
		if (!influencersCtx.selectedInfluencer) influencersCtx.getInfluencer(influencerId);
		if (!influencersCtx.selectedInfluencer.orders) influencersCtx.getInfluencerOrders(influencersCtx.selectedInfluencer.couponCode);
	}, [influencersCtx.selectedInfluencer]);

	let orderList = <p className='lead mb-0'>There are no orders from this influencer's page yet.</p>
	if (influencersCtx.selectedInfluencer.orders){ 
		orderList = influencersCtx.selectedInfluencer.orders.map(order=><Order order={order} key={order.createdAt} />)
	}

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-12 my-5'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<h2>
								{`Orders from ${influencersCtx.selectedInfluencer.firstName} 
								${influencersCtx.selectedInfluencer.lastName}'s Influencer page`}
							</h2>
						</div>
						{orderList}
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default InfluencerOrders;