import { createContext, useState, useContext } from 'react';
import { getAllFBOrders, updateFBOrder } from '../helpers/firebase-helpers';

import AlertContext, {alertMessages} from './alert-context'

const OrdersContext = createContext({
	orders: [],
	isLoading: '',
	error: '',
	isSuccessful: '',
	orderBeingUpdated: '',
	orderUpdateError: '',
	getOrders: () => {},
	updateOrderStatus: (orderId, newStatus) => {}
});

export const OrdersContextProvider = props => {

	const alertCtx = useContext(AlertContext);

	const [orders, setOrders] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isSuccessful, setIsSuccessful] = useState(null);

	const resetStates = () => {
		setIsLoading(false);
		setError(null);
		setIsSuccessful(null);
	}


	const handleSuccess = orders => {
		setOrders(orders);

		setError(null);
		setIsLoading(false);
		setIsSuccessful(true);
	}

	const handleFail = error => {
		setError(error);
		setIsLoading(false);
		setIsSuccessful(null);
	}

	const getOrders = async () => {
		resetStates();
		setIsLoading(true);

		const orders = await getAllFBOrders();
		if (orders) {
			handleSuccess(orders);
			return orders
		} else {
			handleFail({message: 'There aren\'t any orders'});
			return;
		}
	} 

	const updateOrderStatus = async (orderId, newStatus) => {
		const updatedOrders = [...orders];
		const orderIndex = updatedOrders.findIndex(element => element.orderId === orderId);
		updatedOrders[orderIndex].updatingStatus = true;
		updatedOrders[orderIndex].error = null;

		const result = await updateFBOrder(orderId, {status: newStatus});
		if (result.type === 'success') {
			updatedOrders[orderIndex].status = newStatus;
			alertCtx.setAlert('success', alertMessages.ORDER_UPDATED);
		}
		if (result.type === 'error') {
			updatedOrders[orderIndex].error = true;
		}

		updatedOrders[orderIndex].updatingStatus = false;
		setOrders(updatedOrders);

		return true;
	}

	const updateOrderTracking = async (orderId, newTrackingURL) => { 
		const updatedOrders = [...orders];
		const orderIndex = updatedOrders.findIndex(element => element.orderId === orderId);
		updatedOrders[orderIndex].updatingTracking = true;
		updatedOrders[orderIndex].error = null;

		const result = await updateFBOrder(orderId, {trackingURL: newTrackingURL});
		if (result.type === 'success') {
			updatedOrders[orderIndex].trackingURL = newTrackingURL;
			alertCtx.setAlert('success', alertMessages.ORDER_UPDATED);
		}
		if (result.type === 'error') {
			updatedOrders[orderIndex].error = true;
		}

		updatedOrders[orderIndex].updatingTracking = false;
		setOrders(updatedOrders);
	}

	return (
		<OrdersContext.Provider value={{
			orders: orders,
			isLoading: isLoading,
			error: error,
			isSuccessful: isSuccessful,
			getOrders: getOrders,
			updateOrderStatus: updateOrderStatus,
			updateOrderTracking: updateOrderTracking
		}}>
			{props.children}
		</OrdersContext.Provider>
	)

}

export default OrdersContext;

