import {Fragment, useContext, useEffect} from 'react';
import useInput from '../../../hooks/use-input';
import RecipesContext from '../../../store/recipes-context';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import {validateShortText, validateLongText, validateNumberOption, validateURL, validateOptionalShortText} from '../../../helpers/validation-functions';
import {slugify, turnArrayToStringList} from '../../../helpers/misc-helpers';

const UpdateRecipeForm = props => {

	const recipesCtx = useContext(RecipesContext);

	const oliveOilsString = turnArrayToStringList(recipesCtx.selectedRecipe.oliveOils);
	const otherTagsString = turnArrayToStringList(recipesCtx.selectedRecipe.otherTags);
	const ingredientsString = turnArrayToStringList(recipesCtx.selectedRecipe.ingredients);
	const directionsString = turnArrayToStringList(recipesCtx.selectedRecipe.directions);

	const title = useInput(validateShortText, recipesCtx.selectedRecipe.title);
	const description = useInput(validateShortText, recipesCtx.selectedRecipe.description);
	const socialImage = useInput(validateURL, recipesCtx.selectedRecipe.socialImage);
	const ease = useInput(validateNumberOption, recipesCtx.selectedRecipe.ease);
	const goodFor = useInput(validateNumberOption, recipesCtx.selectedRecipe.goodFor);
	const oliveOils = useInput(validateShortText, oliveOilsString);
	const otherTags = useInput(validateShortText, otherTagsString);
	const introduction = useInput(validateLongText, recipesCtx.selectedRecipe.introduction);
	const image = useInput(validateShortText, recipesCtx.selectedRecipe.imageName);
	const instagramLink = useInput(validateURL, recipesCtx.selectedRecipe.instagramLink);
	const ingredients = useInput(validateLongText, ingredientsString);
	const directions = useInput(validateLongText, directionsString);
	

const fields = [
		{
			fid: 0,
			elementType: 'sectionHeader',
			title: 'Marketing information'
		},
		{
			fid: 1,
			elementType: 'input',
			label: 'Recipe title',
      id: 'title',
      type: 'text',
      errorMessage: 'Please enter a valid title',
      value: title.value,
      disabled: false,
      optional: false,
      isValid: title.isValid,
      hasError: title.hasError,
      valueChangedHandler: title.valueChangedHandler,
      touchHandler: title.touchHandler,
      reset: ()=>title.reset()
		},
		{
			fid: 2,
			elementType: 'input',
			label: 'Recipe description (for SEO)',
      id: 'description',
      type: 'text',
      errorMessage: 'Please enter a valid description',
      value: description.value,
      disabled: false,
      optional: false,
      isValid: description.isValid,
      hasError: description.hasError,
      valueChangedHandler: description.valueChangedHandler,
      touchHandler: description.touchHandler,
      reset: ()=>description.reset()
		},
		{
			fid: 3,
			elementType: 'input',
			label: 'Social image url - image that shows when people share recipe (optional)',
      id: 'social-image',
      type: 'text',
      errorMessage: 'Please enter a valid url',
      value: socialImage.value,
      disabled: false,
      optional: true, 
      isValid: socialImage.isValid,
      hasError: socialImage.hasError,
      valueChangedHandler: socialImage.valueChangedHandler,
      touchHandler: socialImage.touchHandler,
      reset: ()=>socialImage.reset()
		},
		{
			fid: 4,
			elementType: 'sectionHeader',
			title: 'Recipe metadata (tags)'
		},
		{
			fid: 5,
			elementType: 'select',
			label: 'Good for',
      id: 'good-for',
      type: 'text',
      errorMessage: 'Please select a valid value',
      value: goodFor.value,
      options: [
      	{
      		value: 0,
      		displayValue: 'Please make your selection'
      	},
      	{
					value: 1,
					displayValue: 'Breakfast'
      	},
      	{
					value: 2,
					displayValue: 'Lunch'
      	},
      	{
					value: 3,
					displayValue: 'Dinner'
      	},
      	{
					value: 4,
					displayValue: 'Snack'
      	},
      	{
					value: 5,
					displayValue: 'Side'
      	}
      ],
      disabled: false,
      optional: false,
      isValid: goodFor.isValid,
      hasError: goodFor.hasError,
      valueChangedHandler: goodFor.valueChangedHandler,
      touchHandler: goodFor.touchHandler,
      reset: ()=>goodFor.reset()
		},
		{
			fid: 6,
			elementType: 'select',
			label: 'Ease of preparation',
      id: 'ease',
      type: 'text',
      errorMessage: 'Please select a valid ease level',
      value: ease.value,
      options: [
      	{
      		value: 0,
      		displayValue: 'Please make your selection'
      	},
      	{
					value: 1,
					displayValue: 'Super-easy'
      	},
      	{
					value: 2,
					displayValue: 'Fairly-easy'
      	},
      	{
					value: 3,
					displayValue: 'Not-so-easy'
      	},
      	{
					value: 4,
					displayValue: 'Difficult'
      	}
      ],
      disabled: false,
      optional: false,
      isValid: ease.isValid,
      hasError: ease.hasError,
      valueChangedHandler: ease.valueChangedHandler,
      touchHandler: ease.touchHandler,
      reset: ()=>ease.reset()
		},
		{
			fid: 7,
			elementType: 'textarea',
			label: 'Olive oils used',
      id: 'olive-oils',
      rows: 3,
      type: 'text',
      errorMessage: 'Please enter a valid list of olive oil',
      helpText: 'Put each olive oil used on a new line. Acceptable olive oil names: Premium, Luxe, Private Reserve.',
      value: oliveOils.value,
      disabled: false,
      optional: false, 
      isValid: oliveOils.isValid,
      hasError: oliveOils.hasError,
      valueChangedHandler: oliveOils.valueChangedHandler,
      touchHandler: oliveOils.touchHandler,
      reset: ()=>oliveOils.reset()
		},
		{
			fid: 8,
			elementType: 'textarea',
			label: 'Other tags (optional)',
      id: 'other-tags',
      rows: 6,
      type: 'text',
      errorMessage: 'Please enter a valid list of tags',
      helpText: 'Put each tag used on a new line. Acceptable tags: Vegetarian, Vegan, Family-recipe, Only-ingredients-from-Costco',
      value: otherTags.value,
      disabled: false,
      optional: true, 
      isValid: otherTags.isValid,
      hasError: otherTags.hasError,
      valueChangedHandler: otherTags.valueChangedHandler,
      touchHandler: otherTags.touchHandler,
      reset: ()=>otherTags.reset()
		},
		{
			fid: 9,
			elementType: 'sectionHeader',
			title: 'Recipe content'
		},
		{
			fid: 10,
			elementType: 'textarea',
			label: 'Introduction',
      id: 'introduction',
      type: 'text',
      errorMessage: 'Please enter a valid introduction',
      helpText: 'Eg. "Perfect salad for hot summers, popularized by Jennifer Aniston"',
      value: introduction.value,
      disabled: false,
      optional: false,
      isValid: introduction.isValid,
      hasError: introduction.hasError,
      valueChangedHandler: introduction.valueChangedHandler,
      touchHandler: introduction.touchHandler,
      reset: ()=>introduction.reset()
		},
		{
			fid: 11,
			elementType: 'input',
			label: 'Image file name',
      id: 'image',
      type: 'text',
      errorMessage: 'Please enter a valid file name',
      helpText: 'Eg. "artichokes.webp"',
      value: image.value,
      disabled: false,
      optional: false,
      isValid: image.isValid,
      hasError: image.hasError,
      valueChangedHandler: image.valueChangedHandler,
      touchHandler: image.touchHandler,
      reset: ()=>image.reset()
		},
		{
			fid: 12,
			elementType: 'input',
			label: 'Instagram post link (optional)',
      id: 'instagram',
      type: 'text',
      errorMessage: 'Please enter a valid url',
      value: instagramLink.value,
      disabled: false,
      optional: true,
      isValid: instagramLink.isValid,
      hasError: instagramLink.hasError,
      valueChangedHandler: instagramLink.valueChangedHandler,
      touchHandler: instagramLink.touchHandler,
      reset: ()=>instagramLink.reset()
		},
		{
			fid: 13,
			elementType: 'textarea',
			label: 'Ingredients',
      id: 'ingredients',
      rows: 6,
      type: 'text',
      errorMessage: 'Please enter a valid list of ingredients',
      helpText: 'Put each ingredient on a new line',
      value: ingredients.value,
      disabled: false,
      optional: false,
      isValid: ingredients.isValid,
      hasError: ingredients.hasError,
      valueChangedHandler: ingredients.valueChangedHandler,
      touchHandler: ingredients.touchHandler,
      reset: ()=>ingredients.reset()
		},
		{
			fid: 14,
			elementType: 'textarea',
			label: 'Directions',
      id: 'directions',
      rows: 6,
      type: 'text',
      errorMessage: 'Please enter a valid list of directions',
      helpText: 'Put each direction on a new line',
      value: directions.value,
      disabled: false,
      optional: false,
      isValid: directions.isValid,
      hasError: directions.hasError,
      valueChangedHandler: directions.valueChangedHandler,
      touchHandler: directions.touchHandler,
      reset: ()=>directions.reset()
		}
	];

	const sanitizeArray = (acceptedValuesArray, inputArray) => {
		const sanitizedArray = [];

		inputArray.map(element=>{
			if(acceptedValuesArray.includes(element)) sanitizedArray.push(element);
		})
		return sanitizedArray ;
	}

	const submitHandler = async event => {
		event.preventDefault();

		const acceptedOliveOilValues = ['Premium', 'Luxe', 'Private Reserve'];
		const acceptedTags = ['Vegetarian', 'Vegan', 'Family-recipe', 'Only-ingredients-from-Costco'];

		const slug = slugify(title.value)
		const oliveOilsArray = oliveOils.value.split('\n');
		const sanitizedOliveOilsArray = sanitizeArray(acceptedOliveOilValues, oliveOilsArray);
		const otherTagsArray = otherTags.value ? otherTags.value.split('\n') : null;
		const sanitizedOtherTagsArray = otherTagsArray.length > 0 ? sanitizeArray(acceptedTags, otherTagsArray) : null;
		const ingredientsArray = ingredients.value.split('\n');
		const directionsArray = directions.value.split('\n');

		let recipe= {
			title: title.value,
			description: description.value,
			slug: slug,
			goodFor: goodFor.value,
			ease: ease.value,
			introduction: introduction.value,
			imageName: image.value,
			ingredients: ingredientsArray,
			directions: directionsArray
		}

		if (socialImage.value) recipe = {...recipe, socialImageUrl: socialImage.value}
		if (sanitizedOliveOilsArray.length>0)	recipe = {...recipe, oliveOils: sanitizedOliveOilsArray}
		if (sanitizedOtherTagsArray.length>0) recipe = {...recipe, otherTags: sanitizedOtherTagsArray}
		if (instagramLink.value) recipe = {...recipe, instagramLink: instagramLink.value}		

		const updatedRecipe = await recipesCtx.updateRecipe(recipesCtx.selectedRecipe.recipeId, recipe);		
	}

	let formArea = (
		<Form fields={fields} submitHandler={submitHandler} serverError={recipesCtx.error}>
      Update recipe
    </Form>
	);

	if (recipesCtx.isLoading) formArea = <Loader>Updating your recipe...</Loader>

	return (
		<Fragment>
			{formArea}
	  </Fragment>
	)
}

export default UpdateRecipeForm;
