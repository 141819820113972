const ShippingItem = props => {

	const priceCopy = props.price ? `$${props.price}` : '$0'
	const shippingHeaderCopy = props.price  ? 'Standard shipping' : 'Free shipping'; 

	return (
		<div className='d-flex justify-content-between align-items-center mb-4'>
			<p className='lead mb-0'>{shippingHeaderCopy}</p>
			<p className='lead mb-0'>{priceCopy}</p>
		</div>
	);

}

export default ShippingItem;