import {Fragment, useContext} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import AuthContext from '../../store/auth-context';

import Home from '../Home/Home';
import Orders from '../Orders/Orders';
import Recipes from '../Recipes/Recipes';
import CreateRecipe from '../CreateRecipe/CreateRecipe';
import UpdateRecipe from '../UpdateRecipe/UpdateRecipe';
import Influencers from '../Influencers/Influencers';
import CreateInfluencer from '../CreateInfluencer/CreateInfluencer';
import UpdateInfluencer from '../UpdateInfluencer/UpdateInfluencer';
import InfluencerOrders from '../InfluencerOrders/InfluencerOrders';
import styles from './App.module.css';

const App = () => {

	const authCtx = useContext(AuthContext);

	let authProtectedRoutes = null;
	if (authCtx.isLoggedIn) {
		authProtectedRoutes = (
			<Fragment>
				<Route path='/orders' element={<Orders />}></Route>
				<Route path='/recipes' element={<Recipes />}></Route>
				<Route path='/recipes/create' element={<CreateRecipe />}></Route>
				<Route path='/recipes/update/:recipeId' element={<UpdateRecipe />}></Route>
				<Route path='/influencers' element={<Influencers />}></Route>
				<Route path='/influencers/create' element={<CreateInfluencer />}></Route>
				<Route path='/influencers/update/:influencerId' element={<UpdateInfluencer />}></Route>
				<Route path='/influencers/:influencerId/orders' element={<InfluencerOrders />}></Route>
			</Fragment>
		)
	}

	return (
		<div className={styles.Global}>
			<Routes>
				<Route path='/' element={<Home />}></Route>
				{authProtectedRoutes}
				<Route path='*' element={<Navigate to="/" replace />} />
			</Routes>
		</div>
	)
}

export default App;