import { useContext } from 'react';
import AuthContext from '../../store/auth-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import SignInForm from '../../components/Forms/SignInForm/SignInForm';

import styles from './Home.module.css';

const Home = () => {

	const authCtx = useContext(AuthContext);

	let signInContent = <SignInForm />
	if (authCtx.isLoggedIn) signInContent = <p className='lead mb-0 text-center'> You are signed in</p>

	return (
		<MainLayout>
			<div className='container'>
				<div className='row my-5'>
					<div className='col-12 my-5'>
						<h1 className='display-6 text-center'>Welcome to the True Olive Admin Panel</h1>
						<div className={styles.SignInContainer}>
							{signInContent}
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default Home;