const Input = props => {

  let inputElement = null;

  let inputStyles = 'form-control';
  if (props.field.hasError) inputStyles = 'form-control is-invalid';
  if (props.field.isValid) inputStyles = 'form-control is-valid';

  const marginSize = props.noMargin ? 'mb-0' : 'mb-3';

  const isLarge = props.large ? 'form-select-lg' : '';

  switch (props.field.elementType) {
    case('input'):
      inputElement = <input 
        type={props.field.type}
        id={props.field.id} 
        disabled={props.field.disabled}
        onChange={props.field.valueChangedHandler}
        onBlur={()=>props.field.touchHandler(true)} 
        onFocus={()=>props.field.touchHandler(false)} 
        value={props.field.value}
        placeholder={props.field.placeholder}
        className={inputStyles} 
      /> 
      break;
    case('select'):      
      inputElement = <select 
        className={`form-select ${isLarge}`} 
        value={props.field.value}
        onChange={props.field.valueChangedHandler}
      >
        {props.field.options.map(option=>{
          return <option key={option.value} value={option.value}>{option.displayValue}</option>
        })}
      </select>
      break;
     case('checkbox'):      
      inputElement = (
        <div className='form-check form-check-inline'>
          <input 
            className='form-check-input'
            value={props.field.value}
            onChange={props.field.valueChangedHandler}
            id={props.field.id}
           / >
          <label className='form-check-label' for={props.field.id}>
            {props.field.displayValue}
          </label>
        </div>
      )
      break;
     case('textarea'):
      inputElement = <textarea 
        type={props.field.type}
        id={props.field.id} 
        rows={props.field.rows}
        disabled={props.field.disabled}
        onChange={props.field.valueChangedHandler}
        onBlur={()=>props.field.touchHandler(true)} 
        onFocus={()=>props.field.touchHandler(false)} 
        value={props.field.value}
        className={inputStyles} 
      /> 
      break;
    default:  
      inputElement = null;
  }

  let labelElement = null;
  if (props.field.label) {
    labelElement = (
      <label 
        htmlFor={props.field.id}
        className='form-label'
       >
        {props.field.label}
      </label>
    )
  } 

  return (
    <div className={`${marginSize} text-start`}>
      {labelElement}
      {inputElement}
      {
        props.field.helpText && <div className='form-text'>{props.field.helpText}</div>
      }
      {
         props.field.hasError && <div className='invalid-feedback'>{props.field.errorMessage}</div>
      }
    </div>
)
}

export default Input;