import { useContext, useEffect } from 'react';
import RecipesContext from '../../store/recipes-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Loader from '../../components/UIElements/Loader/Loader';
import PrimaryCTALink from '../../components/UIElements/Buttons/PrimaryCTALink/PrimaryCTALink';
import Recipe from './Recipe/Recipe';

const Recipes = () => {

	const recipesCtx = useContext(RecipesContext);

	useEffect(()=>{
		if (recipesCtx.recipes.length === 0) recipesCtx.getRecipes(); 
	}, [recipesCtx.recipes]);

	let recipeList = null;
	if (recipesCtx.isLoading) recipeList = <Loader>Loading recipes</Loader>
	if (recipesCtx.recipes.length > 0) {
		recipeList = recipesCtx.recipes.map(recipe=><Recipe recipe={recipe} key={recipe.recipeId} />)
	}
	if (!recipesCtx.isLoading && recipesCtx.isSuccessful && recipesCtx.recipes.length === 0){
		recipeList = <p className='lead mb-0'> You don't have any recipes yet</p>
	}

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-12 my-5'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<h3>Recipes</h3>
							<PrimaryCTALink destination='/recipes/create'>Add new recipe</PrimaryCTALink>
						</div>
						<div>
							{recipeList}
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default Recipes;