import {Fragment} from 'react';

const Select = props => {

	const isLarge = props.large ? 'form-select-lg' : '';

	return (
		<Fragment>
			<select 
        className={`form-select ${isLarge}`} 
        value={props.field.value}
        onChange={props.field.valueChangedHandler}
      >
        {props.field.options.map(option=>{
          return <option key={option.value} value={option.value}>{option.displayValue}</option>
        })}
      </select>
      {
         props.field.hasError && <div className='invalid-feedback d-block bg-danger text-white px-2'>{props.field.errorMessage}</div>
      }
		</Fragment>
	)
}

export default Select;