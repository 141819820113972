import styles from './SectionHeader.module.css';

const SectionHeader = props => {
	return (
		<div className='d-flex align-items-center'>
			<div className={styles.Left}></div>
			<h5 className='mx-2'>{props.children}</h5>
			<div className={styles.Right}></div>
		</div>
	)
}

export default SectionHeader