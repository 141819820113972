import { useContext } from 'react';
import InfluencersContext from '../../../store/influencers-context';

import { Link } from "react-router-dom";
import {transformSecondsToDisplayDate} from '../../../helpers/misc-helpers';

import styles from './Influencer.module.css';

const Influencer = props => {

	const influencersCtx = useContext(InfluencersContext);
	
	let creationDate = 'Just created';
	if (props.influencer.createdAt) creationDate = transformSecondsToDisplayDate(props.influencer.createdAt.seconds);	

	return (
		<div className={styles.Influencer}>
			<div className='d-flex align-items-center justify-content-between mb-3'>
				<div>
					<h3 className='mb-0'>{`${props.influencer.firstName} ${props.influencer.lastName}`}</h3>
					<p className='mb-0'><small>{`Page created on: ${creationDate}`}</small></p>
				</div>
				<h5 className='mb-0'>{props.influencer.couponCode}</h5>
			</div>
			<div className='d-flex'>
				<Link to={`/influencers/${props.influencer.influencerId}/orders`}>
          <button 
          	className='btn btn-link p-0 me-2' 
          	onClick={()=>influencersCtx.selectInfluencer(props.influencer.influencerId)}
          >
            See orders
          </button>
        </Link>
				<Link to={`/influencers/update/${props.influencer.influencerId}`}>
          <button 
          	className='btn btn-link p-0 me-2' 
          	onClick={()=>influencersCtx.selectInfluencer(props.influencer.influencerId)}
          >
            Edit
          </button>
        </Link>
	        <button 
	        	className='btn btn-link text-danger p-0' 
	        	onClick={()=>influencersCtx.deleteInfluencer(props.influencer.influencerId)}
	        >
	        	Delete
	        </button>
			</div>
		</div>
	)
}

export default Influencer;