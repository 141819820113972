import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo.webp';

const Logo = props => {
	let logoHeight = '69px';
	let logoWidth = '81px';
	if (props.small) {
		logoHeight = '49px';
		logoWidth = '58px';
	}

	const styles = {
		width: logoWidth,
		height: logoHeight,
		backgroundImage: `url(${logo})`,
		backgroundSize: 'cover',
  	backgroundRepeat: 'no-repeat',
	}

	return (
		<Link to='/'>
			<div style={styles}></div>
		</Link>	
	)
}

export default Logo;