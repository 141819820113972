import BtnText from '../../Buttons/BtnText/BtnText';

const AddMoreFieldsBtn = props => {
	return (
		<BtnText clickHandler={props.clickHandler}>
			<i className="bi bi-plus-circle"></i>
			<p className='mb-0 ms-2'>{props.children}</p>
		</BtnText>
	)
}

export default AddMoreFieldsBtn