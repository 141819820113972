import styles from './ShippingAddress.module.css';
import TrackingInfo from '../TrackingInfo/TrackingInfo';

const ShippingAddress = props => {

	return (
		<div className={`${styles.ShippingAddress} d-md-flex align-items-center`}>
			<p className='lead mb-0 me-3'> Shipping <br className='d-none d-lg-block'/>address</p>
			<div>
				<p className='mb-0'>
					<small>{`${props.order.firstName} ${props.order.lastName} - ${props.order.email}`}</small>
				</p>
				<p className='mb-0'><small>{props.order.address.line1}</small></p>
				<p className='mb-0'><small>{props.order.address.line2}</small></p>
				<p className='mb-0'>
					<small>
						{`${props.order.address.city}, ${props.order.address.state}, ${props.order.address.country}, ${props.order.address.postal_code}`}
					</small>
				</p>
				<TrackingInfo 
					orderId={props.order.orderId} 
					email={props.order.email}
					firstName={props.order.firstName}
					fullName={`${props.order.firstName} ${props.order.lastName}`}
					trackingURL={props.order.trackingURL} 
					updating={props.order.updatingTracking} 
				/>
			</div>
		</div>
	)
}

export default ShippingAddress;