import Logo from '../../UIElements/Logo/Logo';

import styles from './Footer.module.css';

const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<div className={styles.Footer}>
			<div className='container'>
				<div className='row'>
					<div className='col-12 d-flex justify-content-between align-items-center'>
						<Logo small/>
						<p className='m-0 lead'> &copy; <span>{year}</span> </p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer;