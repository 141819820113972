import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InfluencersContext from '../../store/influencers-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Loader from '../../components/UIElements/Loader/Loader';
import UpdateInfluencerForm from '../../components/Forms/UpdateInfluencerForm/UpdateInfluencerForm';

const UpdateInfluencer = () => {

	const influencersCtx = useContext(InfluencersContext);
	const { influencerId } = useParams();

	useEffect(()=>{
		if(influencersCtx.influencers.lenghth === 0) influencersCtx.getInfluencers();
		if (!influencersCtx.selectedInfluencer) influencersCtx.getInfluencer(influencerId);
	}, [influencersCtx.selectedInfluencer]);

	let formArea = <h5>Form goes here</h5>;// null
	if (influencersCtx.isLoading) formArea = <Loader>Loading influencer...</Loader>
	if (influencersCtx.selectedInfluencer && influencersCtx.isSuccessful) {
		formArea = <UpdateInfluencerForm />
	}	

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 my-5'>
						<h3>Update influencer data</h3>
						{formArea}
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default UpdateInfluencer;