import { firebaseDB } from './firebase-config'; 
import { firebaseAuth } from './firebase-config';
import { doc, getDoc, setDoc, deleteDoc, updateDoc, collection, query, where, getDocs, addDoc, orderBy } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";

// AUTH & USER HELPERS
// Signin
export const signInWithFBAuth = async (email, password) => {
	return await signInWithEmailAndPassword(firebaseAuth, email, password)
}

// Fetch user data by id 
export const getFBUserInfo = async userId => {
	const userDocRef = doc(firebaseDB, 'users', userId);
	const userSnapshot = await getDoc(userDocRef);
	if(userSnapshot.exists()) return userSnapshot.data();
	return null;
}

// Fetch user data by email
export const getFBUserInfoByEmail = async email => {
	const userRef = collection(firebaseDB, 'users');
	const getUserByEmailQ = query(userRef, where('email', '==', email))
	const querySnapshot = await getDocs(getUserByEmailQ);
	if(querySnapshot.empty) return false;
	return {userId: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data()}
}

// ORDER HELPERS
// Get Orders
export const getAllFBOrders = async () => {
	const ordersRef = collection(firebaseDB, 'orders');
	const getOrders = query(ordersRef, orderBy('createdAt'));
	const querySnapshot = await getDocs(getOrders);
	if(querySnapshot.empty) return false;
	const orders = [];
	querySnapshot.docs.forEach(doc=>{
		orders.push({orderId: doc.id, ...doc.data()});
	})
	return orders.reverse();
}

// Get all orders associated with a couponCode
export const getAllOrdersWithCouponCode = async couponCode => {
	const ordersRef = collection(firebaseDB, 'orders');
	const getOrdersByCouponCodeQ = query(ordersRef, where('promotionCode.codeName', '==', couponCode), orderBy('createdAt'))
	const querySnapshot = await getDocs(getOrdersByCouponCodeQ);	
	if(querySnapshot.empty) return false;
	const orders = [];
	querySnapshot.docs.forEach(doc=>{
		orders.push(doc.data());
	})
	return orders.reverse();
}

// Update an order
export const updateFBOrder = async (orderId, dataToUpdate) => {
	const orderDocRef = doc(firebaseDB, 'orders', orderId);
	const orderSnapshot = await getDoc(orderDocRef);

	if(orderSnapshot.exists()) {
		try {
			await updateDoc(orderDocRef, {
				...dataToUpdate,
				updatedAt: new Date()
			});
			return {
				type: 'success',
				data: {
					...dataToUpdate
				}
			}
		} catch (error) {
			return {type: 'error', message: 'Failed to update the order'} 
		}	
	}
}

// RECIPE HELPERS
// Create a Recipe
export const createFBRecipe = async recipeData => {
	const recipesRef = collection(firebaseDB, 'recipes');
	try {
		const docRef = await addDoc(recipesRef, {...recipeData, createdAt: new Date()});
		return {
			recipeId: docRef.id,
			...recipeData
		};	
	} catch (error) {
		return({type: 'error', message: error.message});
	}	
}

// Get Recipes
export const getFBRecipes = async () => {
	const recipesRef = collection(firebaseDB, 'recipes');
	const getRecipes = query(recipesRef, orderBy('createdAt'));
	const querySnapshot = await getDocs(getRecipes);
	if(querySnapshot.empty) return false;
	const recipes = [];
	querySnapshot.docs.forEach(doc=>{
		recipes.push({recipeId: doc.id, ...doc.data()});
	})
	return recipes.reverse();
}

// Get Recipe 
export const getFBRecipe = async recipeId => {
	const recipeDocRef = doc(firebaseDB, 'recipes', recipeId);
	const recipeSnapshot = await getDoc(recipeDocRef);
	if(recipeSnapshot.exists()) {
		return {recipeId: recipeId, ...recipeSnapshot.data()};
	}
	return null;
}

// Update Recipe
export const updateFBRecipe = async (recipeId, dataToUpdate) => {
	const recipeDocRef = await doc(firebaseDB, 'recipes', recipeId);
	const recipeSnapshot = await getDoc(recipeDocRef);
	if(recipeSnapshot.exists()) {
		try {
			await updateDoc(recipeDocRef, {
				...dataToUpdate,
				updatedAt: new Date()
			});
			return {
				type: 'success',
				data: {recipeId: recipeId, ...dataToUpdate}
			}
		} catch (error) {
			return {type: 'error', message: 'Failed to update the recipe information'} 
		}	
	}
	return {type: 'error', message: 'A recipe with this id does not exist'};  
}


// Delete Recipe
export const deleteFBRecipe = async recipeId => {
	const recipeRef = doc(firebaseDB, 'recipes', recipeId);
	try {
		await deleteDoc(recipeRef);
		return { type: 'success' };
	} catch (error) {
		return({type: 'error', message: error.message});
	}
}

// INFLUENCER HELPERS
// Create an Influencer
export const createFBInfluencer = async influencerData => {
	const influencersRef = collection(firebaseDB, 'influencers');
	try {
		const docRef = await addDoc(influencersRef, {...influencerData, createdAt: new Date()});
		return {
			influencerId: docRef.id,
			...influencerData
		};	
	} catch (error) {
		return({type: 'error', message: error.message});
	}	
}

// Get Influencers
export const getFBInfluencers = async () => {
	const influencersRef = collection(firebaseDB, 'influencers');
	const getInfluencers = query(influencersRef, orderBy('createdAt'));
	const querySnapshot = await getDocs(getInfluencers);
	if(querySnapshot.empty) return false;
	const influencers = [];
	querySnapshot.docs.forEach(doc=>{
		influencers.push({influencerId: doc.id, ...doc.data()});
	})
	return influencers.reverse();
}

// Get Influencer 
export const getFBInfluencer = async influencerId => {
	const influencerDocRef = doc(firebaseDB, 'influencers', influencerId);
	const influencerSnapshot = await getDoc(influencerDocRef);
	if(influencerSnapshot.exists()) {
		return {influencerId: influencerId, ...influencerSnapshot.data()};
	}
	return null;
}

// Update Influencer
export const updateFBInfluencer = async (influencerId, dataToUpdate) => {
	const influencerDocRef = await doc(firebaseDB, 'influencers', influencerId);
	const influencerSnapshot = await getDoc(influencerDocRef);
	if(influencerSnapshot.exists()) {
		try {
			await updateDoc(influencerDocRef, {
				...dataToUpdate,
				updatedAt: new Date()
			});
			return {
				type: 'success',
				data: {influencerId: influencerId, ...dataToUpdate}
			}
		} catch (error) {
			return {type: 'error', message: 'Failed to update the influencer information'} 
		}	
	}
	return {type: 'error', message: 'An influencer with this id does not exist'};  
}

// Delete Influencer
export const deleteFBInfluencer = async influencerId => {
	const influencerRef = doc(firebaseDB, 'influencers', influencerId);
	try {
		await deleteDoc(influencerRef);
		return { type: 'success' };
	} catch (error) {
		return({type: 'error', message: error.message});
	}
}
