import {postData} from './http-helpers';

//TEST EMAIL
export const fireTestEmail = async () => {

	try {
  	const response = await postData(
  		`${process.env.REACT_APP_FUNCTIONS_BASE_URL}fireTestEmail`,
  		{
  			customerEmail: "babaoglu.can@gmail.com",
  			customerName: "Can Babaoglu",
  		}
    );
    console.log('success')
		return true;
  } catch (error) {
  	return false;
  	console.log('failed')
  }
}

//ORDERR TRACKING EMAIL
export const fireTrackingEmail = async emailData => {

  try {
    const response = await postData(
      `${process.env.REACT_APP_FUNCTIONS_BASE_URL}fireTrackingEmail`,
      {
        customerEmail: emailData.email,
        customerName: emailData.fullName,
        customerFirstName: emailData.firstName,
        trackingURL: emailData.trackingURL
      }
    );
    console.log('success')
    return true;
  } catch (error) {
    return false;
    console.log('failed')
  }
}