import { useState, useContext, useEffect } from 'react';
import OrdersContext from '../../store/orders-context';

import Select from '../../components/UIElements/Form/Select/Select';
import Loader from '../../components/UIElements/Loader/Loader';
import Order from '../../components/UIElements/Order/Order';
import MainLayout from '../../components/Layouts/MainLayout/MainLayout';

const Orders = () => {

	const [tab, setTab] = useState('new');
	const [newOrders, setNewOrders] = useState([]);
	const [inPrepOrders, setInPrepOrders] = useState([]);
	const [shippedOrders, setShippedOrders] = useState([]);
	const [problematicOrders, setProblematicOrders] = useState([]);

	const ordersCtx = useContext(OrdersContext);

	useEffect(() => {
		if (ordersCtx.orders.length === 0) ordersCtx.getOrders(); 
		if (ordersCtx.orders.length > 0) {
			setNewOrders(ordersCtx.orders.filter(order => order.status === 'new'));
			setInPrepOrders(ordersCtx.orders.filter(order => order.status === 'inPrep'));
			setShippedOrders(ordersCtx.orders.filter(order => order.status === 'shipped'));
			setProblematicOrders(ordersCtx.orders.filter(order => ['cancelled', 'outOfStock', 'disputed', 'refunded'].includes(order.status)))
		}
	}, [ordersCtx.orders]);


	let orderList = null;
	if (ordersCtx.isLoading) orderList = <Loader>Loading orders</Loader>
	if (!ordersCtx.isLoading && ordersCtx.orders.length > 0) {
		if (tab === 'new'){
			if(newOrders.length === 0) orderList = <p className='lead mb-0'> There aren't any orders meeting this criteria</p>
			if(newOrders.length > 0) orderList = newOrders.map(order=><Order order={order} key={order.createdAt} updatable/>)
		}
		if (tab === 'inPrep'){
			if(inPrepOrders.length === 0) orderList = <p className='lead mb-0'> There aren't any orders meeting this criteria</p>
			if(inPrepOrders.length > 0) orderList = inPrepOrders.map(order=><Order order={order} key={order.createdAt} updatable/>)
		}
		if (tab === 'shipped'){
			if(shippedOrders.length === 0) orderList = <p className='lead mb-0'> There aren't any orders meeting this criteria</p>
			if(shippedOrders.length > 0) orderList = shippedOrders.map(order=><Order order={order} key={order.createdAt} updatable/>)
		}
		if (tab === 'hasProblem'){
			if(problematicOrders.length === 0) orderList = <p className='lead mb-0'> There aren't any orders meeting this criteria</p>
			if(problematicOrders.length > 0) orderList = problematicOrders.map(order=><Order order={order} key={order.createdAt} updatable/>)
		}
	}
		

	const selectField = {
		value: tab,
		valueChangedHandler: event => setTab(event.target.value),
		options: [
			{
				value: 'new',
				displayValue: 'New orders'
			},
			{
				value: 'inPrep',
				displayValue: 'Orders being prepared'
			},
			{
				value: 'shipped',
				displayValue: 'Shipped orders'
			},
			{
				value: 'hasProblem',
				displayValue: 'Problematic orders'
			}
		]
	}

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-12 my-5'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<h3>Orders</h3>
							<div><Select field = {selectField} /></div>
						</div>
						{orderList}
					</div>
				</div>
			</div>
		</MainLayout>
	)

}

export default Orders;