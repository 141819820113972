import styles from './Loader.module.css';

const Loader = props => {

	const darkColor = props.dark ? styles.Dark : '';

	return (
		<div className='d-flex align-items-center'>
			<div className={`${styles.Loader} ${darkColor}`}></div>
			<p className='lead mb-0'>{props.children ? props.children : 'Loading...'}</p>
		</div>
	)
}

export default Loader;