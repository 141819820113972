import {Fragment, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import useInput from '../../../hooks/use-input';

import AuthContext from '../../../store/auth-context';
import UserContext from '../../../store/user-context';

import Form from '../../UIElements/Form/Form';
import {validateEmail, validatePassword} from '../../../helpers/validation-functions';

const SignInForm = props => {

  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);

	const email = useInput(validateEmail);
	const password = useInput(validatePassword);

  const [error, setError] = useState(null);

	const fields = [
		{
			fid: 0,
      elementType: 'input',
      label: 'Your email',
      id: `${props.inline ? 'inline-' :''}email`,
      type: 'email',
      errorMessage: 'Please enter a valid email',
      value: props.email ? props.email : email.value,
      disabled: props.email ? true : false,
      isValid: email.isValid,
      hasError: email.hasError,
      valueChangedHandler: email.valueChangedHandler,
      touchHandler: email.touchHandler,
      reset: ()=>email.reset()
		},
		{
			fid: 1,
      elementType: 'input',
      label: 'Your password',
      id: `${props.inline ? 'inline-' :''}password`,
      type: `password`,
      errorMessage: 'Please enter a valid password (at least 6 characters)',
      value: password.value,
      isValid: password.isValid,
      hasError: password.hasError,
      valueChangedHandler: password.valueChangedHandler,
      touchHandler: password.touchHandler,
      reset: ()=>password.reset()
		}
	];


	const submitHandler = async event => {
    event.preventDefault();
    setError(null);

    fields.map(field=>field.reset());

    const user = await userCtx.getUserInfoByEmail(props.email ? props.email : email.value);

    if(user) {
      if (user.isAdmin){
        const authUser = await authCtx.onLogin({
          email: props.email ? props.email : email.value,
          password: password.value
        });
        if (authUser) { 
          navigate('/orders'); 
        } else {
          setError(authCtx.error);
        }    
      } else {
        setError({message: 'This user is not an admin.'})  
      }   
    } else {
      setError({message: 'A user with that email does not exist.'});
    }   
  }

  return (
  	<Fragment>
  		<h4 className='mb-4'>Sign in with your admin account</h4>
  		<Form fields={fields} submitHandler={submitHandler} serverError={error}>
        Sign in
      </Form>
  	</Fragment>
  )

}

export default SignInForm;