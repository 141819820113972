import {Fragment, useState} from 'react'; 

import CreateTrackingInfoForm from '../../../Forms/CreateTrackingInfoForm/CreateTrackingInfoForm';
import UpdateTrackingInfoForm from '../../../Forms/UpdateTrackingInfoForm/UpdateTrackingInfoForm';
import BtnText from '../../Buttons/BtnText/BtnText';
import FormWrapper from '../../FormWrapper/FormWrapper';
import Loader from '../../Loader/Loader';

import styles from './TrackingInfo.module.css';

const TrackingInfo = props => {

	const [trackingURL, setTrackingURL] = useState(props.trackingURL)
	const [displayCreateForm, setDisplayCreateForm] = useState(false)
	const [displayUpdateForm, setDisplayUpdateForm] = useState(false)

	let displayElement = <BtnText clickHandler={()=>setDisplayCreateForm(true)}><small>Add tracking URL</small></BtnText>

	if (props.updating) {
		displayElement = <FormWrapper><Loader>Updating tracking...</Loader></FormWrapper>
	}
	
	if (displayCreateForm) {
		displayElement = ( 
			<FormWrapper>
				<div className='d-flex align-items-center'>
					<CreateTrackingInfoForm 
						orderId={props.orderId}
						email={props.email}
						firstName={props.firstName}
						fullName={props.fullName}
					/>  
					<BtnText passOnClasses='ms-3' clickHandler={()=>setDisplayCreateForm(false)}>Close</BtnText>
				</div>
			</FormWrapper>
		);
	}  
	
	if (props.trackingURL) {
		displayElement = (
			<div className='d-flex'>
				<p className='mb-0 me-2'><small>
					<a 
						href={props.trackingURL} 
						target="_blank"
						className={styles.TrackingLink}
					>Track package</a>
				</small></p>
				<BtnText clickHandler={()=>setDisplayUpdateForm(true)}><small>Update tracking info</small></BtnText>
			</div>
		)
	}

	if (displayUpdateForm) {
		displayElement = ( 
			<FormWrapper>
				<div className='d-flex align-items-center'>
					<UpdateTrackingInfoForm 
						orderId={props.orderId} 
						trackingURL={props.trackingURL}
						email={props.email}
						firstName={props.firstName}
						fullName={props.fullName}
					/>  
					<BtnText passOnClasses='ms-3' clickHandler={()=>setDisplayUpdateForm(false)}>Close</BtnText>
				</div>
			</FormWrapper>
		);
	}

	if (trackingURL != props.trackingURL) {
		setDisplayUpdateForm(false);
		setTrackingURL(props.trackingURL);
	}

	return <Fragment>{displayElement}</Fragment>

}

export default TrackingInfo;



