import { useContext, useEffect } from 'react';
import InfluencersContext from '../../store/influencers-context';

import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import PrimaryCTALink from '../../components/UIElements/Buttons/PrimaryCTALink/PrimaryCTALink';
import Loader from '../../components/UIElements/Loader/Loader';
import Influencer from './Influencer/Influencer';

const Influencers = props => {

	const influencersCtx = useContext(InfluencersContext);

	useEffect(()=>{
		if (influencersCtx.influencers.length === 0) influencersCtx.getInfluencers(); 
	}, [influencersCtx.influencers]);

	let influencerList = null;
	if (influencersCtx.isLoading) influencerList = <Loader>Loading influencers...</Loader>
	if (influencersCtx.influencers.length > 0) {
		influencerList = influencersCtx.influencers.map(influencer=><Influencer influencer={influencer} key={influencer.influencerId} />)
	}
	if (!influencersCtx.isLoading && influencersCtx.influencers.length === 0){
		influencerList = <p className='lead mb-0'> You don't have any influencers yet</p>
	}

	return (
		<MainLayout>
			<div className='container'>
				<div className='row'>
					<div className='col-12 my-5'>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<h3>Influencers</h3>
							<PrimaryCTALink destination='/influencers/create'>Add new influencer</PrimaryCTALink>
						</div>
						<div>
							{influencerList}
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default Influencers;