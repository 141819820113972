import {useContext, useCallback} from 'react';
import useInput from '../../../hooks/use-input';

import OrdersContext from '../../../store/orders-context';

import Form from '../../UIElements/Form/Form';
import Loader from '../../UIElements/Loader/Loader';
import {validateURL} from '../../../helpers/validation-functions';
import {fireTrackingEmail} from '../../../helpers/email-helpers';

const CreateTrackingInfoForm = props => {

	const ordersCtx = useContext(OrdersContext);
	const trackingURL = useInput(validateURL);
	const fields = [
		{
			fid: 0,
			elementType: 'input',
      id: 'trackingURL',
      type: 'text',
      errorMessage: 'Please enter a valid URL',
      placeholder: 'Enter tracking URL',
      value: trackingURL.value,
      disabled: false,
      isValid: trackingURL.isValid,
      hasError: trackingURL.hasError,
      valueChangedHandler: trackingURL.valueChangedHandler,
      touchHandler: trackingURL.touchHandler,
      reset: ()=>trackingURL.reset()
		}
	]

	const handleTracking = useCallback( async trackingURL => {
		const tracking = await ordersCtx.updateOrderTracking(props.orderId, trackingURL);
		const email = await fireTrackingEmail({
			email: props.email,
			firstName: props.firstName,
			fullName: props.fullName,
			trackingURL: trackingURL	
		});
	},[]);
		
	const submitHandler = event => {
		event.preventDefault();
		handleTracking(trackingURL.value);
	}

	return (
		<Form fields={fields} submitHandler={submitHandler} serverError={''} inline noMargin>
			Add
		</Form>
	)


}

export default CreateTrackingInfoForm;