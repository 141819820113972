export const validateEmail = value => {
  const validatedEmail = String(value.trim())
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return validatedEmail ? true : false;
};

export const validatePassword = value => {
  if (value.trim().length >= 6) return true;
  return false;
}

export const validateShortText = value => {
  if (value.trim().length >= 3) return true;
  return false;
}

export const validateLongText = value => {
  if (value.trim().length >= 20) return true;
  return false;
}

export const validateURL = value => {
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export const validateNumberOption = value => {
  if (value>=1 && value <=5) return true;
  return false;
} 

export const validateArray = value => {
  if (value.length >= 0) return true;
  return false;
}

export const validateCheckbox = value => {
  if (value.length >= 0) return true;
  return false;
}