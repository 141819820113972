import { useState, useContext } from 'react';
import AuthContext from '../../../store/auth-context';
import Logo from '../../UIElements/Logo/Logo';
import Navbar from '../Navbar/Navbar';

const MainNavbar = props => {

	const authCtx = useContext(AuthContext);

	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	const path = window.location.pathname;

	const navItems = [
		{
			id: 0,
			copy: 'Home',
			type: 'link',
			destination: '/',
			showTo: 'everyone', 
			active: path === '/' ? true : false,
		},
		{
			id: 1,
			copy: 'Orders',
			type: 'link',
			destination: '/orders',
			showTo: 'authenticated',
			active: path === '/orders' ? true : false,
		},
		{
			id: 2,
			copy: 'Influencers',
			type: 'link',
			destination: '/influencers',
			showTo: 'authenticated',
			active: path === '/influencers' ? true : false,
		},
		{
			id: 3,
			copy: 'Recipes',
			type: 'link',
			destination: '/recipes',
			showTo: 'authenticated',
			active: path === '/recipes' ? true : false,
		},
		{
			id: 4,
			copy: 'Logout',
			type: 'link-btn',
			showTo: 'authenticated',
			clickHandler: () => authCtx.onLogout()
		}
	]

	return (
		<nav className='navbar navbar-dark bg-primary'>
			<div className='container'>
				<div className='d-flex align-items-center'>
					<Logo />
					<h5 className='m-0 ps-4 text-light'>Admin Panel</h5>	
				</div>	
				<button 
					className='custom-toggler navbar-toggler d-lg-none' 
					type='button' 
					data-toggle='collapse'
					data-target='#navbarExpand' 
					aria-controls='navbarExpand' 
					aria-expanded={!isNavCollapsed ? true : false} 
					aria-label='Toggle navigation' 
					onClick={handleNavCollapse}
				>
					<span className='navbar-toggler-icon'></span>
    		</button>		
				<div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id='navbarExpand'>
					<Navbar authenticated={authCtx.isLoggedIn}  navItems={[...navItems]} />
				</div>
				<div className='d-none d-lg-flex align-items-center'>
      		<Navbar authenticated={authCtx.isLoggedIn}  navItems={[...navItems]} />
      	</div>
			</div>
		</nav>
	)

}

export default MainNavbar;